/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { formatISO } from "date-fns"

type ogImage = { childImageSharp: { resize: { src: string } } }
type metaArray = {
  name: string
  content: string
}[]

/** Automatically filled in SEO tags by passing the data from BasicSocial fragment */
export const BasicSEO: React.FC<{
  social: any
  excerpt: string
  ogImage?: ogImage
  withPubDate: boolean
  type?: "website" | "article"
  meta?: metaArray
  title?: string
  bodyClass?: string
}> = ({ social, withPubDate, type, excerpt, title, ogImage, bodyClass }) => {
  const hasDate = withPubDate && social.date !== null
  const date = new Date(social.date)

  return (
    <SEO
      title={title ?? social.title}
      description={social.description || excerpt}
      robots={social.robots}
      publishedAt={hasDate ? formatISO(date) : undefined}
      updatedDate={
        withPubDate && social.updatedDate
          ? formatISO(new Date(social.updatedDate))
          : undefined
      }
      ogImage={ogImage ?? social.image}
      type={type ?? "article"}
      bodyClass={bodyClass}
    />
  )
}
interface Props {
  description?: string
  lang?: string
  meta?: metaArray
  title?: string
  robots?: string
  ogImage?: ogImage
  publishedAt?: string
  updatedDate?: string
  bodyClass?: string
  type?: "website" | "article"
}

const SEO: React.FC<Props> = ({
  description,
  lang = "en",
  meta,
  title,
  robots,
  ogImage,
  publishedAt,
  updatedDate,
  bodyClass,
  type = "website",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
          }
        }
      }
    `
  )

  const socialImage = ogImage
    ? site.siteMetadata.siteUrl + ogImage?.childImageSharp?.resize?.src
    : undefined
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: bodyClass,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: "/favicon_32.png",
          sizes: "32x32",
        },
        {
          rel: "icon",
          type: "image/png",
          href: "/favicon_64.png",
          sizes: "64x64",
        },
        {
          rel: "profile",
          href: "http://microformats.org/profile/hcard",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title ?? site.siteMetadata.title,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        socialImage && {
          property: `og:image`,
          content: socialImage,
        },
        {
          name: `twitter:card`,
          content: socialImage ? "summary_large_image" : `summary`,
        },
        {
          name: `twitter:creator`,
          content: "marcohamersma",
        },
        {
          name: `twitter:title`,
          content: title ?? site.siteMetadata.title,
        },
        socialImage && {
          name: `twitter:image`,
          content: socialImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        type === "article" && publishedAt
          ? {
              name: "article:published_time",
              content: publishedAt,
            }
          : undefined,
        type === "article" && updatedDate
          ? {
              name: "article:modified_time",
              content: updatedDate,
            }
          : undefined,
        robots
          ? {
              name: "robots",
              content: robots,
            }
          : undefined,
      ]
        .concat(meta)
        .filter(Boolean)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object) as any,
  title: PropTypes.string,
}

export default SEO
