import { format } from "date-fns"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import { useLittlefoot } from "../littlefoot"
import { Aside } from "./Markdown"
import { Unpublished } from "./Unpublished"

export const MHArticle: React.FC<{
  isOld?: boolean
  title: string
  subtitle?: React.ReactNode
  byline?: React.ReactNode
  date?: string
  updatedDate?: string
  body: any
  /** Add this if the post is old! */
  age?: any
  showDate?: boolean
  asArticle?: boolean
  license?: string
  unpublished?: boolean
  children?: React.ReactNode
}> = (post) => {
  const isOld = post.age && post.age > 3
  const showDate = post.showDate ?? true
  const license =
    post.license ?? "https://creativecommons.org/licenses/by-nc/4.0/"

  useLittlefoot()

  return (
    <article
      className="markdown"
      itemScope={post.asArticle}
      itemType={post.asArticle ? "https://schema.org/BlogPosting" : undefined}
    >
      <header className="n-spacing-bottom-medium n-text-centered">
        <h1 itemProp="headline">{post.title}</h1>

        {post.byline ? (
          <p className="n-type-smallest n-color-mid">{post.byline}</p>
        ) : null}

        {post.date ? (
          showDate ? (
            <time
              dateTime={post.date}
              className="n-type-smallest n-color-mid"
              itemProp="datePublished"
            >
              {format(new Date(post.date), "MMMM dd, yyyy")}
            </time>
          ) : (
            <meta itemProp="datePublished" content={post.date} />
          )
        ) : null}

        {post.updatedDate && (
          <meta itemProp="dateModified" content={post.updatedDate} />
        )}

        {post.unpublished && (
          <div className="n-text-centered">
            <Unpublished />
          </div>
        )}

        {post.subtitle ? (
          <p className="n-color-mid n-type-larger n-spacing-top-small n-spacing-bottom-medium">
            <em>{post.subtitle}</em>
          </p>
        ) : null}
      </header>

      {post.asArticle && <meta itemProp="author" content="Marco Hamersma" />}
      {post.asArticle && <meta itemProp="license" content={license} />}

      {isOld ? (
        <Aside className="n-vspacing-medium n-type-small font-bold">
          Please note that this post is more than {post.age} years old and
          should be considered dated. It's mostly here as part of an archive of
          what I've done over the years.
        </Aside>
      ) : null}

      <section itemProp="articleBody">
        <MDXRenderer>{post.body}</MDXRenderer>

        {post.children}
      </section>
    </article>
  )
}
