import React from "react"
import { Link, graphql } from "gatsby"
import {} from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { MHArticle } from "../components/MHArticle"
import { Unpublished } from "../components/Unpublished"

class NowPageTemplate extends React.Component<MHPageProps> {
  render() {
    const node = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const dateTitle = node.frontmatter.nowDate ?? node.frontmatter.formattedDate

    return (
      <>
        <SEO
          title={` What I was up to in ${dateTitle}`}
          description={`An overview of stuff I was working on or other stuff that kept me occupied in ${dateTitle}.`}
        />

        <div className="oneColumn">
          <MHArticle
            title="What I was up to"
            subtitle={
              <>
                An overview of stuff I was working on or other stuff that kept
                me occupied in {dateTitle}. Inspired by the movement of{" "}
                <a
                  href="https://nownownow.com/about"
                  target="_blank"
                  rel="noopener"
                >
                  /now pages
                </a>
                .{node.frontmatter.published === false && <Unpublished />}
              </>
            }
            body={node.body}
            date={node.frontmatter.date}
            updatedDate={node.frontmatter.updatedDate}
            showDate={false}
            asArticle
          />

          <div role="presentation" className="hr n-vspacing-medium" />

          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link
                    className="g-link n-type-smallest n-color-mid nowrap"
                    to={previous.fields.slug}
                    rel="prev"
                  >
                    ←{" "}
                    {previous.frontmatter.nowDate ??
                      previous.frontmatter.nowPageDate}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link
                    className="g-link n-type-smallest n-color-mid nowrap"
                    to={next.fields.slug}
                    rel="next"
                  >
                    {next.frontmatter.nowDate ?? next.frontmatter.nowPageDate} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

export default NowPageTemplate

export const pageQuery = graphql`
  query NowPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
        contentType
      }
      frontmatter {
        date
        updatedDate
        published
        nowDate
        formattedDate: date(formatString: "MMMM YYYY")
      }
    }
  }
`
