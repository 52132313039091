import React from "react"
import type LittlefootType from "littlefoot"

export const useLittlefoot = () => {
  const littlefoot = React.useRef<ReturnType<typeof LittlefootType>>()

  React.useEffect(() => {
    Promise.all([
      import("littlefoot"),
      import("littlefoot/dist/littlefoot.css"),
    ]).then(
      ([foot]) => {
        littlefoot.current = foot.default({
          buttonTemplate: `
            <button
              type="button"
              aria-label="Footnote <% number %>"
              class="littlefootnote-ref"
              id="<% reference %>"
              title="See Footnote <% number %>"
            />
              <% number %>
            </button>
            `,
        })

        // Undo littlefoot's work on hiding the footnote list
        // https://github.com/goblindegook/littlefoot/issues/380#issuecomment-651067982
        document
          .querySelectorAll(
            ".footnotes.footnote-print-only, .footnotes .footnote-print-only"
          )
          .forEach((e) => e.classList.remove("footnote-print-only"))

        document
          .querySelectorAll<HTMLAnchorElement>(".footnote-backref")
          .forEach((el) => {
            el.href = el.href.replace("#", "#lf-")
          })
      },
      (e) => {
        console.log("could not load littlefoot", e)
      }
    )

    return () => littlefoot.current?.unmount()
  })
}
